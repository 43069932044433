
import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./post.module.scss"

const PostTemplate = (props) => {

	const {
		data: {
			postInfo,
			postInfo: {
				title,
				date,
				ACFPostInfo: {
					postInfo: {
						left: {
							blurb,
							requestAppointmentLink,
							insurancesHeading,
							callout: {
								backgroundImage: calloutBG,
								blurb: calloutBlurb,
								heading: calloutHeading,
								link: calloutLink
							}
						},
						right: {
							postContent: content,
						}
					}
				}
			},
			home: {
				ACFHomePage: {
					hero: {
						callouts: {
							callout1: {
								heading: homeCalloutHeading,
								blurb: homeCalloutBlurb,
								link: homeCalloutLink,
							}
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
			calloutBG: genericCalloutBG,
			blogPages: {
				nodes: blogPages
			}
		}
	} = props;

	return (
		<Layout>
			<Seo post={postInfo} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						{ blogPages && blogPages.length > 0 && <h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: blogPages[0].title}}></h1> }
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styleSheet.request}>Request appointment</Link> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					{ blogPages && blogPages.length > 0 && <Link to={blogPages[0].link} className={styleSheet.moreblog}>Find more posts</Link> }
					{ title && <h1 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: title}}></h1> }
					{ date && <span className={styleSheet.date}>{date}</span> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
					<div className={styleSheet.callout}>
						<div className={styleSheet.left}>
							{ (genericCalloutBG || calloutBG) && <FluidImage image={calloutBG ? calloutBG : genericCalloutBG} passedClass={styleSheet.bg} /> }
						</div>
						<div className={styleSheet.right}>
							{ (calloutHeading || homeCalloutHeading) && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading ? calloutHeading : homeCalloutHeading }} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
							{ (calloutBlurb || homeCalloutBlurb) && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: calloutBlurb ? calloutBlurb : homeCalloutBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
							{ (homeCalloutLink || calloutLink) && <WPLink link={calloutLink ? calloutLink : homeCalloutLink} passedClass={styleSheet.link} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.patientcontent}>
						<div className={styleSheet.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  postInfo: wpPost(id: {eq: $id}) {
    id
    title
    date(formatString: "MMMM D, YYYY")
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFPostInfo {
      postInfo {
        left {
          blurb
          requestAppointmentLink {
            title
            url
            target
          }
          insurancesHeading
          callout {
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                publicURL
                extension
              }
            }
            blurb
            heading
            link {
              target
              title
              url
            }
          }
        }
        right {
          postContent
        }
      }
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            heading
            blurb
            link {
              target
              title
              url
            }
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  appointmentPage: allWpPage(
    filter: {template: {templateName: {eq: "Appointments Page"}}}
  ) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  calloutBG: file(name: {eq: "callout_overlay_girl"}) {
    childImageSharp {
      gatsbyImageData(width: 295, layout: CONSTRAINED)
    }
  }
  insurance: allWpInsurance {
    nodes {
      ACFInsurance {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 420, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
  blogPages: allWpPage(
    filter: {template: {templateName: {eq: "Blog Page"}}, status: {eq: "publish"}}
  ) {
    nodes {
      id
      link
      title
    }
  }
}
`;

export default PostTemplate;